const projectsData = [
  {
    date: "2024-May",
    title: "BuenoVisionairo.com",
    madeAt: "Freelance",
    builtWith: "JavaScript, HTML, CSS, WordPress",
    url: "https://buenovisionairo.com",
    description: `I developed BuenoVisionairo.com, a modern and accessible
    website for a custom clothing sale service. This project
    involved creating a clean and professional interface,
    integrating essential features like product catalog
    and secure payment options, and optimizing for both
    performance and security. The design focused on
    user-friendliness and clear navigation to ensure an
    optimal user experience. Through this project, I
    enhanced my skills in web development, user interface
    design, and e-commerce solutions.`,
    logo: require("../assets/images/projects/bueno/logo3-200x.png"),
  },
  {
    date: "2024-May",
    title: "CherryHillCPR.com",
    madeAt: "Freelance",
    builtWith: "JavaScript, WordPress, HTML, CSS ",
    url: "https://cherryhillcpr.com",
    description: `I developed cherryhillcpr.com, a modern and accessible
    website for a CPR training service. This project
    involved creating a clean and professional interface,
    integrating essential features like course scheduling
    and secure payment options, and optimizing for both
    performance and security. The design focused on
    user-friendliness and clear navigation to ensure an
    optimal user experience. Through this project, I
    enhanced my skills in web development, user interface
    design, and e-commerce solutions.`,
    logo: require("../assets/images/projects/cherry-hill-cpr/CHCPR-logo-200x.png"),
  },
  {
    date: "2024-May",
    title: "My-FishTank.com",
    madeAt: "Freelance",
    builtWith: "JavaScript, HTML, CSS, WordPress",
    url: "https://my-fishtank.com",
    description: `I developed my-fishtank.com, a WordPress-based
    e-commerce website specializing in marine supplies and
    corals. This project involved creating a visually
    appealing and user-friendly interface, integrating
    essential features like secure payment gateways and user
    account management, and optimizing for performance and
    security. Additionally, I developed detailed product
    descriptions and care guides to enhance SEO and user
    engagement. Through this project, I refined my skills in
    WordPress development, e-commerce solutions, and web
    design, delivering a professional and efficient platform
    for marine enthusiasts.`,
    logo: require("../assets/images/projects/my-fishtank/logo-myfishtank-85w.png"),
  },
  {
    date: "2024-April",
    title: "SOA Example",
    madeAt: "Freelance",
    builtWith: "JavaScript, HTML, CSS",
    url: "/SOAExample",
    description: `This example demonstrates how I can apply SOA principles in your React application by creating modular and reusable services.`,
    logo: require("../assets/images/projects/main/logo-soa.png"),
  },
  {
    date: "2024-Pres",
    title: "BlackJack Web-Game",
    madeAt: "Portfolio Project",
    builtWith: "JavaScript, HTML, CSS, React",
    url: "https://dmitricode.com",
    description: `I developed a fully-functional BlackJack game,
    leveraging my expertise in JavaScript, HTML, and CSS.
    This project showcased my ability to create an engaging
    and visually appealing game. Key responsibilities
    included designing and implementing game logic for a
    seamless user experience, using React for a dynamic and
    responsive UI, and ensuring performance and security
    with Firebase as the backend service. I also
    collaborated with peers for code reviews and
    optimizations, emphasizing clean and maintainable code.`,
    logo: require("../assets/images/projects/blackjack/logo1-200x.png"),
  },
  {
    date: "2024-Mar",
    title: "8 Sorting Algorithms",
    madeAt: "Portfolio Project",
    builtWith: "React, JavaScript, HTML, CSS, Bootstrap",
    url: "https://v1.dmitrimorozov.com",
    description: `Discover the essentials of sorting with "8 Must-Know
    Sorting Algorithms," a dynamic educational tool built
    using JavaScript, Bootstrap, and React. This interactive
    webpage showcases the differences and efficiencies of
    various algorithms through engaging visuals and clear
    explanations. Whether you're a novice or a practiced
    coder, this project offers a practical look at
    algorithmic sorting, demonstrating each method's unique
    characteristics in an accessible format powered by
    modern web technologies.`,
    logo: require("../assets/images/projects/sorting-algorithms/logo.png"),
  },
  {
    date: "2023-Dec",
    title: "Biz Card - Figma",
    madeAt: "Portfolio Project",
    builtWith: "Figma, HTML, CSS, React",
    url: "https://v2.dmitrimorozov.com",
    description: `Digital Busines Card`,
    logo: require("../assets/images/projects/figma-digital-bc/logo.png"),
  },
  {
    date: "2018-2022",
    title: "1000gal Project - Blog",
    madeAt: "Atlanta Reef",
    builtWith: "React, JavaScript,Bootstrap, HTML, CSS",
    url: "https://1000gal.dmitrimorozov.com",
    description: `Explore the comprehensive journey of setting up and
    maintaining a 1000-gallon aquarium, documented from 2018
    to 2022. This project delves into the intricacies of
    aquatic design, ecosystem balance, and the technical
    challenges faced during the creation and upkeep of a
    large-scale aquarium. Access detailed insights and
    visual documentation by visiting the dedicated project
    page.`,
    logo: require("../assets/images/projects/1000gal/logo-150x.png"),
  },
];
export default projectsData;
